// src/App.jsx
import React, { lazy, Suspense, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Loader from "./components/Loader/Loader";
import useScrollToTop from "./utils/useScrollToTop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollModal from "./components/ScrollModal";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Services = lazy(() => import("./pages/Services"));
const Resources = lazy(() => import("./pages/Resources"));
const Community = lazy(() => import("./pages/Community"));
const BlogPost = lazy(() => import("./pages/BlogPost"));

const AppContent = () => {
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [targetId, setTargetId] = useState('');
	const location = useLocation();

	useScrollToTop();

	useEffect(() => {
		const handleStart = () => {
			setLoading(true);
		};

		const handleComplete = () => {
			setLoading(false);
		};

		handleStart();
		handleComplete();

		return () => {
			handleComplete();
		};
	}, [location]);

	useEffect(() => {
		AOS.init({});
	}, []);

	// useEffect(() => {
	// 	if (location.hash) {
	// 		setTargetId(location.hash.substring(1)); // Remove the '#' from the hash
	// 		setShowModal(true); // Show the modal
	// 	}
	// }, [location]);

	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<>
			{loading && <Loader />}
			<main className="main-content">
				<Suspense fallback={<Loader />}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/services" element={<Services />} />
						<Route path="/resources" element={<Resources />} />
						<Route path="/resources/:id" element={<BlogPost />} />
						<Route path="/community" element={<Community />} />
					</Routes>
				</Suspense>
			</main>

			{/* Modal for scrolling */}
			<ScrollModal open={showModal} onClose={closeModal} targetId={targetId} />
		</>
	);
};

const App = () => {
	return (
		<Router>
			<Navbar />
			<AppContent />
			<ToastContainer />
		</Router>
	);
};

export default App;
