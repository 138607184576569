// src/components/ScrollModal.jsx
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

// Define a lemon yellow color
const lemonYellow = '#C8A500'; // You can adjust this color as needed

const ScrollModal = ({ open, onClose, targetId }) => {
  const handleScroll = () => {
    // Directly using the targetId provided by the prop
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      onClose(); // Close the modal after scrolling
    } else {
      console.error(`Element with ID ${targetId} not found.`);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 400 }, // Responsive width
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: 'center', // Center the text
        }}
      >
        {/* <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
          Register
        </Typography> */}

        <Button
  			sx={{
                width: '100%',
                backgroundColor: "#C8A500",
                fontWeight: "700",
                "&:hover": { opacity: "90%", background: '#C8A500' },
            }}
            variant="contained"
            color="primary"
          onClick={handleScroll}
        >
          Go to Section
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{ mt: 2, ml: 1, width: '100%', color: lemonYellow, borderColor: lemonYellow, "&:hover": { opacity: "90%", borderColor: '#C8A500' }, }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ScrollModal;
